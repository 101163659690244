.cabecera-usados {
  position: relative;
  background-repeat: no-repeat;
  max-width:100%;
  box-shadow: 0 3px 5px #666;
  background-image: url('../../images/public/used_vehicles_background.png');
  background-size: cover;
  background-attachment: fixed;
  margin-bottom: 40px;
  background-position: 100% 50%;
}
.usados {
  background: linear-gradient(-45deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.9) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(0,0,0,.8), rgba(0,0,0,.9) 100%);
  opacity: 1;
  padding: 90px 0 0 0;
}
.titulo-usados h2 {
  font-size: 48px;
  text-align: center;
  margin: 0 auto;
  font-family: $titulo-principal-font;
  color:#fff;
}
.formulario-usados {
  position: relative;
  margin: 0 auto;
  background-color:#fff;
  > .svg-inline--fa {
    position: absolute;
    top: 10px;
    left: 0;
    color: $color-primario;
    padding: 0 10px;
  }
  .form-control {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-bottom: 1px solid #545454;
    padding: 10px 10px 10px 35px;
    margin-top:5px;
    box-shadow: none;
    color: $color-secundario;
    border-radius:0;
    font-style:italic;
    font-size: $parrafo;
    font-family: $parrafo-font;
    &:focus {
      color: $color-secundario;
      font-style:normal;
      background: rgba(255, 255, 255, 0.3);
    }
    &::-webkit-input-placeholder {
      color: $color-secundario;
    }
    &::-moz-placeholder {
     color: $color-secundario;
    }
    &:-ms-input-placeholder {
      color: $color-secundario;
    }
  }
}
form {
  margin-bottom:25px;
}
.resultados-busqueda {
  h4{
    font-size: 20px;
    text-align: left;
    font-family: "Raleway", sans-serif;
    color: #545454;
    padding: 0;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
  }
  p{
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 1.7;
    color: #777575;
    margin: 0;
    padding: 0 0 20px 0;
    strong {
      font-weight: 600;
    }
  }
  a {
    list-style-type:none;
    text-decoration:none;
  }
}
.breadcrumbs {
  padding:0 0 15px 0;
  h4{
    font-size: 20px;
    text-align: left;
    font-family: "Raleway", sans-serif;
    color: #545454;
    padding: 0;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    &:after {
      content: "\25BC";
      position: relative;
      top: 2px;
      float: left;
      display: block;
      margin-top: -12px;
      padding:0 5px 0 0;
      color:$color-primario;
      -webkit-transform: rotate(90deg) translateX(50%);
      -moz-transform: rotate(90deg) translateX(50%);
      -ms-transform: rotate(90deg) translateX(50%);
      -o-transform: rotate(90deg) translateX(50%);
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
  .svg-inline--fa {
    margin-left: 5px;
    margin-right: 5px;
  }
  p{
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 1.7;
    color: #777575;
    margin: 0;
    padding: 0 0 20px 0;
    strong {
      font-weight: 600;
    }
  }
  a {
    list-style-type:none;
    text-decoration:none;
    color: #777575;
  }
}
.listado-usados {
  position: relative;
  list-style: none;
  text-align: center;
  figure {
    position: relative;
    float: left;
    overflow: hidden;
    height: auto;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
    img {
      position: relative;
      display: block;
      min-height: 100%;
      max-width: 100%;
      opacity: 0.8;
    }
    figcaption {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.25em;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &::before,
      &::after {
        pointer-events: none;
      }
       > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
      }
    }
    h3 {
      margin: 0;
      word-spacing: -0.15em;
      font-weight: 800;
      font-family: $subtitulos-font;
    }
    .fas {
      position:relative;
      top:50%;
    }
  }
  .autos-usados {
    margin-bottom:40px;
  }
  h3 {
    text-align: left;
    font-family: "Raleway", sans-serif;
    font-size: 30px;
    color: #dc182d;
    padding: 0 0 25px 0;
    margin: 0 auto;
    font-weight: 600;
    text-transform: capitalize;
  }
  a {
    list-style-type:none;
    text-decoration:none;
  }
}
.usados-texto {
  text-align: center;
  padding: 30px 25px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 4px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-radius: 0px 0px 3px 3px;
  overflow: hidden;
  background-color: #fff;
  z-index: 999;
  position: relative;
  h4 {
    font-size: 20px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    color: #545454;
    padding: 0;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
  }
  p{
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 1.7;
    color: #777575;
    margin: 0;
  }
  a {
    color: #545454 !important;
    transition: all .3s;
    &:hover {
      list-style-type: none;
      text-decoration: none;
    }
    &:focus {
      list-style-type: none;
      text-decoration: none;
    }
  }
}
figure.usados-efecto {
  background: -webkit-linear-gradient(-45deg,rgba(255,0,34,1)  0%,rgba(0,0,0,1) 100%);
  background: linear-gradient(-45deg, rgba(255,0,34,1) 0%,rgba(0,0,0,1) 100%);
  overflow: hidden;
  border: 1px solid rgba(255,255,255,.5);
  height:300px;
  width:100%;
  img {
    opacity: .5;

  }
  figcaption::after,
    img {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    max-width:none;
    height:100%;
    width:auto; position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

  }
  figcaption::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    content: '';
    opacity: 0;
    -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
    transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  h3 {
    position: relative;
    top: 45%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    color: #fff;
    font-family: $titulo-principal-font;
    font-size: 40px;
  }
  &:hover {
    img {
      opacity: 0.7;
      -webkit-transform: scale3d(1.05,1.05,1);
      transform: scale3d(1.05,1.05,1,1);
      transform: translate(-50%,-50%);
    }
    figcaption::after {
      opacity: 1;
      -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
      transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
    }
    h3 {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      transform: scale3d(1,1,1);
    }
  }
}
.usados-detalles {
  margin-bottom:60px;
}
.info-especificaciones-usados {
  .table{
    background-color:rgba(0, 0, 0, 0.5);
  }
}
.texto-usados {
  p {
    font-size: $parrafo;
    text-align: center;
    padding: 25px 0;
    line-height: 1.4;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: #fff;
  }
}
