/*Menú secundario*/
.nav-secundario {
  margin: 0;
  padding: 5px 0;
  text-align: center;
  z-index: 99;
  width: 100% !important;
  background-color:rgba(0,0,0,.5);
  .menu-secundario {
    z-index: 9999;
    position: relative;
    > li {
      float: none;
      display: inline-block;
      background: $color-primario;
      border-radius: 6px;
      a {
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 13px;
        text-transform: uppercase;
        color: #fff;
        transition: all 0.35s ease;
        font-family: $parrafo-font;
        &:hover {
          float: none;
          display: inline-block;
          background: #000;
          border-radius: 5px;
          transition: all .4s;
          background: rgba(0,0,0,.4);
        }
        &:focus {
          color: #fff;
          background-color: transparent;
        }
      }
    }
    li.active > a {
        background-color: #000;
        &:hover,
        &::focus {
          background-color: #000;
        }
    }
  }
}

/*MODELOS*/
/*Modelo auto*/
.modelo-auto {
  overflow:hidden;
  background-size:cover;
  background-repeat: no-repeat;
  margin:0 auto;
  img {
  width:100%;
  }
}
.texto-modelo-auto {
  p {
    font-size: $parrafo;
    text-align: center;
    padding: 35px 0;
    line-height: 1.7;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: $color-secundario;
  }
}
.contador-caracteristicas {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.caracteristicas {
  position: relative;
  padding: 35px 0px;
  margin: 0;
  &:after {
    width: 100%;
    height: 0;
    top: auto;
    left: 0;
    bottom: -1px;
    border-bottom: 1px solid rgba(255,255,255,.5);
  }
  &:before {
    height: 100%;
    top: 0;
    left: -1px;
    border-left: 1px solid rgba(255,255,255,.5);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
  }
  .img-responsive {
    display: inline-block;
  }
}
.counters {
  .counter {
    text-align: center;
  }
  strong {
    display: block;
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    color: $color-primario;
    padding-bottom: 15px;
    font-family:$titulo-principal-font;
  }
  .svg-inline--fa {
    display: block;
    font-size: 45px;
    line-height: 35px;
    margin-bottom: 5px;
    color: #888;
  }
  label {
    display: block;
    font-weight: 100;
    font-size: 13px;
    line-height: 20px;
    color:#888;
    font-family: $parrafo-font;
  }
  h4 {
    font-size: 20px;
    color: #545454;
    margin: 0;
    font-family: $parrafo-font;
    padding-bottom: 5px;
    font-weight: 800;
  }
  p {
    font-family: $parrafo-font;
    font-size: $parrafo-font;
    color: #545454;
    margin: 0;
  }
}

/*Diseño*/
.dark {
  background-repeat: no-repeat;
  max-width:100%;
  box-shadow: 0 3px 5px #666;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .tabbed-content .item.active .item-content p { color: #fff; }
}
.dark .tabbed-content {
  padding: 60px 0;
  background: linear-gradient(-45deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,.9) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(0,0,0,.7), rgba(0,0,0,.9) 100%);
}
.tabs {
  ul {
    margin: 0;
    padding: 0;
    li {
      a {
        padding: 10px;
        font-family: $subtitulos-font;
        font-size: 15px;
        color:#fff;
        border-top:1px solid #fff;
        font-weight:500;
        &:hover,
        &:focus{
          color: #fff !important;
          text-decoration: none;
          background: linear-gradient(-45deg, rgba(255,0,34,.85), #000 100%);
          background: -webkit-linear-gradient(-45deg, rgba(255,0,34,.85),#000 100%);
        }
        .active{
          color: #fff;
          text-decoration: none;
          background: linear-gradient(-45deg, rgba(255,0,34,.85), #000 100%);
          background: -webkit-linear-gradient(-45deg, rgba(255,0,34,.85),#000 100%);
          &:hover {
            color:#fff !important;
          }
        }
        &:hover:after {
          color: $color-primario;
        }
        &:after {
          content: "\25BC";
          position: absolute;
          display: block;
          right: 3rem;
          margin-top: -12px;
          -webkit-transform: rotate(-90deg) translateX(50%);
          -moz-transform: rotate(-90deg) translateX(50%);
          -ms-transform: rotate(-90deg) translateX(50%);
          -o-transform: rotate(-90deg) translateX(50%);
          -moz-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
      .active {
        background: linear-gradient(-45deg, rgba(255,0,34,.85), #000 100%);
        background: -webkit-linear-gradient(-45deg, rgba(255,0,34,.85),#000 100%);
        &:after {
           right: 25px;
           color:$color-primario;
        }
      }

    }
  }
}
.item {
  margin:0;
  &::before {
    cursor: pointer;
    font-weight: bold;
    background: #555;
    display: block;
    text-align: center;
    padding: 10px;
    font-family: $subtitulos-font;
    font-size: 15px;
    color: #fff;
    border-top: 1px solid #fff;
    .active {
      &::before {
        color: $color-primario;
        padding: 0.75rem 2rem;
        margin: 0 auto;
        background-color:#666;
        border: none;
        width: 100%;
        font-size: 14px;
        text-align: center;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        font-family: $subtitulos-font;
      }
    }
    .item-content {
      padding: 1em;
      -webkit-transition: opacity 0.4s ease-in-out;
      -moz-transition: opacity 0.4 ease-in-out;
      -o-transition: opacity 0.4s ease-in-out;
      -ms-transition: opacity 0.4s ease-in-out;
      transition: opacity 0.4s ease-in-out;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      background:red;
    }
  }
}
.item.active::before {
  color: #fff;
  padding: 0.75rem 2rem;
  margin: 0 auto;
  background: linear-gradient(-45deg, rgba(255, 0, 34, 0.85), #000 100%);
  background: -webkit-linear-gradient(-45deg, rgba(255, 0, 34, 0.85), #000 100%);
  border: none;
  width: 100%;
  font-size: 14px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.tabbed-content {
  .item {
    min-height: 2em;
    &::before {
      content: attr(data-title);
    }
    .item-content {
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding: 0;
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  .item.active .item-content {
    opacity: 1;
    visibility: visible;
    height: auto !important;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    h3 {
      text-align: left;
      font-family: $subtitulos-font;
      font-size: $subtitulos;
      color: $color-primario;
      padding: 0 0 25px 0;
      margin: 0 auto;
      font-weight: 600;
      text-transform: capitalize;
    }
    p {
      font-family: $parrafo-font;
      font-size: $parrafo;
      line-height: 1.7;
      color: #777575;
      margin: 0;
      padding-top: 25px;
    }
  }
}
.item-content {
  overflow: hidden;
  h3  {
    margin: 0 auto;
  }
}
.item.active {
.item-content {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  }
}

/*MODELOS- Seguridad*/
.light {
  padding: 60px 0;
  background-color: #fff;
  h2 {
    padding-bottom: 20px;
  }
}
.texto p {
  font-size:$parrafo;
  text-align: center;
  padding-bottom:60px;
  line-height: 1.4;
  margin: 0 auto;
  font-family: $parrafo-font;
  color: $color-secundario;
}


/*MODELOS Motor*/
.property_block {
  padding: 180px 0;
  .counters {
     h4 {
      color: #fff !important;
    }
    p {
      color: #fff;
      font-family: $parrafo-font;
      font-size: $parrafo;
      margin: 0;
    }
  }

  .caracteristicas {
    position: relative;
    padding: 35px 0px;
    margin: 0;
    &:after {
      width: 100%;
      height: 0;
      top: auto;
      left: 0;
      bottom: -1px;
      border-bottom: 1px solid rgba(255,255,255,.5);
    }
    &:before {
      height: 100%;
      top: 0;
      left: -1px;
      border-left: 1px solid rgba(255,255,255,.5);
    }
    &:before,&:after {
      content: '';
      position: absolute;
    }
  }
}
.contador-motor {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.titulo-motor {
  h2 {
    font-size: 48px;
    text-align: left;
    margin:0 auto;
    padding-bottom: 15px;
    font-family: $titulo-principal-font;
    color: #fff;
  }
}
.texto-motor {
  p {
    font-family: $parrafo-font;
    font-size: $parrafo;
    line-height: 1.7;
    color: #fff;
    margin: 0;
  }
}
/*MODELOS Transmisión*/
.transmision {
  padding: 70px 0;
}
.titulo-transmision {
  h2 {
    font-size: 48px;
    text-align: center;
    margin: 0 auto;
    font-family: $titulo-principal-font;
    color: $color-secundario;
    padding-bottom: 40px;
  }
}
.texto-transmision {
  p {
    font-family: $parrafo-font;
    font-size: $parrafo;
    line-height: 1.8;
    margin: 0;
    margin: 0 auto;
    color: $color-secundario;
  }
}
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}
.owl-carousel {
  .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }
  .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .owl-wrapper-outer.autoHeight {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
  }
  .owl-item {
    float: left;
  }
}
.owl-controls {
  .owl-page,
  .owl-controls .owl-buttons div {
    cursor: pointer;
    color: #fff
  }
}
.owl-theme {
  .owl-controls {
    margin-top: 10px;
    text-align: center;
    .owl-page {
      display: inline-block;
      zoom: 1;
      *display: inline;
      span {
        display: block;
        width: 12px;
        height: 12px;
        margin: 5px 7px;
        filter: Alpha(Opacity=50);
        opacity: 0.5;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        background: #47708c !important;
      }
      span.owl-numbers {
        height: auto;
        width: auto;
        color: #FFF;
        padding: 2px 10px;
        font-size: 12px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
      }
    }
  }
  .owl-controls.clickable {
    .owl-buttons {
      div:hover {
        filter: Alpha(Opacity=100);
        opacity: 1;
        text-decoration: none;
      }
    }
  }
  .owl-controls .owl-page.active span,
  .owl-theme .owl-controls.clickable .owl-page:hover span {
    filter: Alpha(Opacity=100);
    opacity: 1;
  }
  .owl-dots {
    .owl-dot {
      span {
         margin: 0 5px !important;
       }
    }
  }
  .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background-color:$color-primario;
  }
}

.imagen-galeria {
  position: relative;
  height: 400px;
  img {
    max-height: 500px
  }
  .videos {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 55px;
  }
}
.video-galeria {
  max-height: 252px;
}

/*MODELOS Accesorios*/
.accesorios {
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:cover;
  box-shadow: 0 3px 5px #666;
  background-image: url('../../images/public/fondo-accesorios.jpg')
}

/*MODELOS- Colores*/
.color_block {
  padding: 60px 0;
}
.titulo-colores h2 {
  font-size: 48px;
  text-align: center;
  margin: 0 auto;
  font-family: $titulo-principal-font;
  color: $color-secundario;
  padding-bottom: 40px;
}
.colores-auto {
  border-radius: 4px;
}
.seleccionar-color {
  padding: 0;
  font-size: 0;
  text-align: center;
  display: table;
  width: 100%;
  margin: 0;
  li {
    display: inline-block !important;
    width:145px;
    float: none;
    &:last-child {
      a {
        margin-right: 0;
      }
    }
    a {
      border-radius: 5px 5px 0 0;
      font-size: 14px;
      margin-right: 1px;
      padding: 20px 15px 15px 15px;
      transition: all 0.35s ease;
      border-bottom: 3px solid #f4f4f4;
       background: #fff;
      color: #CCC;
      &:hover {
        background: #f4f4f4;
        color: #CCC;
        border-bottom-color: $color-terciario;
        box-shadow: none;
        transition: all .8s;
      }
      &:active
      &:focus {
        border-bottom: 0;
      }
    }
  }
  li.active {
    a,
    a:hover,
    a:focus {
      background: #FFF;
      border-bottom: 3px solid $color-terciario;
      color: #CCC;
    }
  }
}

.tab-content {
  margin-top: 30px;
}
.color-automovil {
  p{
    font-family: $parrafo-font;
    font-size: $parrafo;
    color: $color-secundario;
    margin: 0;
    font-weight: 500;
    height: 50px;
    overflow: hidden;
    &:hover {
      color:$color-secundario;
    }
  }
}
.circulo {
  width: 50px;
  height: 50px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 3px solid #f4f4f4;
}

/*MODELOS Precios*/
.version_block {
  padding:80px 0;
  .titulo-precios h2{

    padding-bottom: 40px;
  }
  .precio {
    h2 {
      display: block;
      font-weight: bold;
      font-size: 50px !important;
      line-height: 50px;
      color: $color-primario;
      padding-bottom: 15px;
      margin: 0 auto;
      font-family: $titulo-principal-font;
    }
    h4 {
       font-size: 20px;
      text-align: left;
      font-family: "Raleway", sans-serif;
      color: #545454;
      padding: 0;
      margin: 0 auto;
      line-height: 1.5;
      font-weight:600;
      text-transform: uppercase;
    }
  }
}
/*MODELOS Ficha Técnica*/
.especificaciones-lista {
  img {
    margin: 0 auto;
  }
}
.titulo-especificaciones {
  h2 {
    font-size: 48px;
    text-align: left;
    margin: 0 auto;
    font-family: $titulo-principal-font;
    color: #fff;
    padding-bottom: 10px;
  }
}
.tabla-especificaciones {
  background: linear-gradient(-45deg, rgba(255,0,0,.8) 0%, rgba(0,0,0,.8) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(255,0,0,.8), rgba(0,0,0,.8) 100%);
  padding: 60px 0;
}
.subtitulo-especificaciones {
  h4{
    font-size: 20px;
    text-align: left;
    font-family: $parrafo-font;
    color: #fff;
    padding: 10px 0px 20px 0px;
    margin: 0 auto;
    line-height: 1.5;
    text-transform:uppercase;
  }
}
.info-especificaciones {
  display: block;
}
.table {
  td {
    text-align: right;
    color: #fff;
    font-family:$parrafo-font;
    font-size: 12px;
  }
  td.detalle-especificaciones {
    text-align: left;
    font-family:$parrafo-font;
    font-size: 12px;
  }
}
.boton-descargar-archivo {
  font-family:$parrafo-font;
  background: $color-secundario;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight:700;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  display: block;
  float: left;
  padding: 10px 15px 8px 0;
  text-decoration: none;
  border-radius:5px;
  vertical-align: middle;
  margin: 3px;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(0,0,0,.2);
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    border-radius:6px;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus {
    color: #fff !important;
    text-decoration: none;
  }
  &:hover:before,
  &:focus:before {
    transform: scaleY(1);
  }
  p {
    margin:10px;
  }
}
.icono-descargar {
  font-size: 23px;
  line-height: 0.76;
  margin-left:10px;
  font-weight: 100;
  position: relative;
  bottom: 2px;
  color:$color-primario;
  -webkit-transition: all .9s ease;
  margin-right:10px;
  float: left;
  &:hover {
    transform: scale(1.15);
    color: #fff;
  }
}
