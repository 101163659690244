// Colores

$color-primario: #dc182d;
$color-secundario: #545454;
$color-terciario: #000;

// Titulos

$titulo-principal-font: 'Oswald', sans-serif;
$titulo-principal: 48px;
$subtitulos-font: 'Raleway', sans-serif;
$subtitulos: 30px;
$parrafo-font: 'Raleway', sans-serif;
$parrafo: 16px;

// Plugins

$owl-image-path: '~owl.carousel/src/img/';
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$simple-line-font-path: "~simple-line-icons/fonts/" !default;
$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;
