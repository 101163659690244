body {
  margin: 0;
  padding: 0;
}
ul {
  li {
    text-decoration: none;
    list-style-type: none
  }
}

.navbar {
  width: 100%;
  border-radius:0;
  border-bottom: 0;
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: rgba(10, 10, 10, 0.5);
  margin: 0;
  .navbar-header {
    margin:0;
  }
}
.navbar-collapse {
  transition: clip-path ease-in-out 700ms;
}
.navbar-collapse.collapse.in {
  clip-path: circle(150% at top right);
}

/*Menú*/
ul.menu {
  float: right;
  margin:0;
  transition: all 0.35s ease;
  height: auto;
  li{
    list-style: none;
    float: left;
    &.active a {
      background-color: rgba(255,255,255,.2);
      font-weight: bold;
    }
    a{
      list-style: none;
      text-decoration: none !important;
      color: #fff;
      display: block;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      float: left;
      padding: 18px 15px;
      letter-spacing: .6px;
      font-weight: 700;
      color: #fff;
      position: relative;
      font-family: $parrafo-font;
      text-transform: uppercase;
      margin: 0;
      -webkit-transition: visibility .4s ease, top .4s ease, bottom .4s ease, background .4s ease, margin .4s ease, opacity .4s ease;
      transition: visibility .4s ease, top .4s ease, bottom .4s ease, background .4s ease, margin .4s ease, opacity .4s ease;
      &:hover{
        transition: all .4s;
        color: #fff;
      }
      .fa5-text-r { margin-right: 7px; }
      .svg-inline--fa {
        font-size: 13px;
        color: $color-primario;
      }
    }
    .sub-menu{
      position: absolute;
      left:0;
      right: 0;
      margin: 0 auto;
      max-width: 1130px;
      padding: 0 !important;
      top:100%;
      visibility: hidden;
      opacity: 0;
      padding: 40px 0;
      -webkit-transition: visibility .4s ease, top .4s ease, bottom .4s ease, background .4s ease, margin .4s ease, opacity .4s ease;
      transition: visibility .4s ease, top .4s ease, bottom .4s ease, background .4s ease, margin .4s ease, opacity .4s ease;
      background-color:rgba(255,255,255,1);
    }
    &:hover .sub-menu{
      margin-top: 0;
      top: 100%;
      opacity: 1;
      position: absolute;
      visibility: visible;
    }
  }
  a:before,
  a::after {
    height: 1px;
    position: absolute;
    content: '';
    transition: all 0.35s ease;
    width: 0;
  }
  a::before {
    top: 0;
    left: 0;
  }
  a::after {
    bottom: 0;
    right: 0;
  }
  &:hover,
  li.active > a  {
    transition: all 0.35s ease;
    border-bottom: 0;
  }
  a {
    &:hover::before,
    &:hover::after,
    .active a::before,
    .active a::after {
      width: 100%;
    }
  }
}
ul.menu,
.sub-menu{
  padding-left: 0;
}
.sub-menu {
border-bottom:1px solid red;
  li {
    &:hover{
      transition: .3s all ease-in;
      background-color: rgba(225,225,225,0.8);
    }
    a {
      padding: 10px 27px;
      width: 100%;
      background-color: rgba(220,24,45,0.8);
      p {
        color: $color-primario;
        margin: 0 auto;
        border-top: 1px dotted #8c8c8c;
        padding-top: 10px;
        font-weight: bold;
        font-family: $parrafo-font;
      }
    }
    img {
      padding-bottom: 10px;
    }
  }
}
ul.sub-menu {
  li {
    a {
      padding: 10px 27px;
      width: 100%;
      background-color: rgba(255,255,255,1);
      z-index:999999;
    }
  }
}
/*Logo menú*/
.logo {
  .logo-white {
    max-height: 40px;
    transition: max-height 0.4s;
    max-width: none;
    position: absolute;
    opacity: 1;
    margin-top: 5px;
  }
  .logo-black {
    max-height: 40px;
    transition: max-height 0.4s;
    max-width: none;
    margin-top: 5px;
  }
}
.logo-honda {
  float: left;
  display: block;
  height: 40px;
  top: 9px;
  padding-right: 15px;
  position: relative;
  transition: max-height 0.4s;
}

/*Efecto Scroll*/
.scrolled {
  ul.menu {
    li {
      a {
        padding: 18px 10px;
        width: 100%;
        -webkit-transition: all ease-in-out 0.4s;
        -moz-transition: all ease-in-out 0.4s;
        -o-transition: all ease-in-out 0.4s;
        -ms-transition: all ease-in-out 0.4s;
        color: #353535;
        &:hover{
          background: rgba(125,125,125,.8);
        }
      }
    }
  }
  ul.sub-menu {
    li {
      a {
        color: #fff;
        padding: 10px 27px;
        background-color:rgba(255,255,255,1);
      }
    }
  }
  .logo {
    .logo-white {
      max-height: 36px;
      transition: max-height 0.4s, opacity 0.3s;
      opacity: 0;
      margin-top: 8px;
    }
    .logo-black {
      max-height: 36px;
      transition: max-height 0.4s, opacity 0.3s;
      margin-top: 8px;
    }
    .logo-honda {
      max-height: 38px;
      top: 5px;
      transition: max-height 0.4s, opacity 0.3s;
    }
  }
}

/*Botón Hamburguesa*/
.hamburger {
  padding: 15px 15px;
  display: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
  float: right;
  &:hover {
    opacity: 0.7;
  }
}
.hamburger.is-active {
  &:hover {
    opacity: 0.7;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #fff;
  }
}
.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 30px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  &::before,
  &::after {
    width: 30px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    content: "";
    display: block;
  }
  &::before {
    top: -10px;
  }
  &::after {
    bottom: -10px;
  }
}
.hamburger--collapse-r {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }
    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55,0.055, 0.675, 0.19);
    }
  }
}
.hamburger--collapse-r.is-active {
  .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    }
    &::before {
      top: 0;
      transform: rotate(90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

/*EFECTO Botones Menú*/
div.topBotomBordersOut {
  a {
    &:before {
      position: absolute;
      left: 0px;
      width: 100%;
      height: 2px;
      background: #FFF;
      content: "";
      opacity: 0;
      transition: all 0.3s;
      top: 0px;
      transform: translateY(10px);
    }
    &:hover:before{
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

/*Slider*/
.owl-carousel.owl-loaded {
  display: grid;
}
.slider {
  .contenido-slider {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    span {
      font-family: $parrafo-font;
      font-size:$parrafo;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 3px;
      display: inline-block;
      position: relative;
      top: 70px;
      opacity: 0;
      color:#fff;
      background-color: $color-primario;
      padding:2px 5px;
    }
    h2 {
      font-family: $titulo-principal-font;
      font-size: 60px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 0;
      position: relative;
      top: 70px;
      opacity: 0;
      color:#fff;
    }
    p {
      font-size: 20px;
      font-family: 'Raleway', sans-serif;
      color: #fff;
      letter-spacing: 1px;
      margin-bottom: 35px;
      position: relative;
      top: 100px;
      opacity: 0;
      color:#fff;
    }
    .boton-slider {
      position: relative;
      top: 50px;
      opacity: 0;
      margin-right: 5px;
      background-color: $color-primario;
      font-size: $parrafo;
      min-width: 167px;
      padding: 15px;
      border-radius: 10px;
      border-color: transparent;
      text-transform: uppercase;
      font-family: $parrafo-font;
      letter-spacing: 1px;
      background: $color-secundario;
      color: #fff;
      cursor: pointer;
      text-align: center;
      font-weight:700;
      &:hover {
        color: #fff;
        text-decoration:none;
      }
      :before {
        content: "";
        position: absolute;
        z-index: -1;
        background: rgba(0,0,0,.2);
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        right: 0;
        transform: scaleX(0);
        transform-origin: 50%;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }
      &:hover,
      &:focus {
        color: #fff !important;
        text-decoration: none;
      }
      &:hover:before,
      &:focus:before {
        transform: scaleY(1);
      }
    }
    .container {
      position: relative;
      padding-top: 40vh;
    }
    .overlay-slider {
      background: linear-gradient(-45deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.6) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(0,0,0,.5), rgba(0,0,0,.6) 100%);
      height: 100%;
    }
  }
  .slider-nav-warp {
    max-width: 1145px;
    bottom: 0;
    margin: -78px auto 0;
    text-align: center;
  }
  .slider-nav {
    display: inline-block;
    padding: 0 38px;
    position: relative;
  }
  .owl-dots {
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 9px;
    .owl-dot {
      width: 8px;
      height: 8px;
      background: #fff;
      border-radius: 15px;
      margin-right: 10px;
      opacity: 0.25;

      span { display: none; }
    }
    .owl-dot.active {
      opacity: 1;
      background-color: $color-primario;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .owl-nav button.owl-next,
  .owl-nav button.owl-prev {
    font-size: 27px;
    position: absolute;
    color: #fff;
    opacity: 0.5;
    bottom: -20px;
  }
  .owl-nav button.owl-next {
    right: 0;
  }
  .owl-nav button.owl-prev {
    left: 0;
  }
  .owl-item.active .contenido-slider h2,
  .owl-item.active .contenido-slider span,
  .owl-item.active .contenido-slider p,
  .owl-item.active .contenido-slider .boton-slider {
    top: 0;
    opacity: 1;
  }
  .owl-item.active {
    .contenido-slider {
      span {
        -webkit-transition: all 0.5s ease 0.2s;
        -o-transition: all 0.5s ease 0.2s;
        transition: all 0.5s ease 0.2s;
      }
      h2 {
        -webkit-transition: all 0.5s ease 0.4s;
        -o-transition: all 0.5s ease 0.4s;
        transition: all 0.5s ease 0.4s;
      }
      p {
        -webkit-transition: all 0.5s ease 0.6s;
        -o-transition: all 0.5s ease 0.6s;
        transition: all 0.5s ease 0.6s;
      }
      .boton-slider {
        -webkit-transition: all 0.5s ease 0.8s;
        -webkit-transition: all 0.5s ease 0.8s;
        -o-transition: all 0.5s ease 0.8s;
        transition: all 0.5s ease 0.8s;
      }
    }
  }
}



/*Texto Presentación Home*/
.texto-home {
  overflow: hidden;
  padding: 60px 0;
  background: $color-primario;
  box-shadow: 0 3px 5px #666;
  p {
    font-size: $parrafo;
    text-align: center;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: #fff;
    line-height: 1.5;
  }
}
.titulo-home {
  margin-bottom: 40px;
  h2 {
    font-size: 48px;
    text-align: center;
    margin: 0 auto;
    font-family: $titulo-principal-font;
    color: #fff;
  }
}
.titulo h2, .descubri-honda h2 {
  font-size: 48px;
  text-align: center;
  margin: 0 auto;
  font-family: $titulo-principal-font;
  padding-bottom:40px;
}
.dark h2 { color: #fff }
.light h2 { color: $color-secundario }

.descubri-honda {
  padding: 60px 0;
  overflow: hidden;
}


/*Categorias Home*/
.categorias-home {
  position: relative;
  margin-bottom:60px;
  list-style: none;
  text-align: center;
  figure {
    position: relative;
    float: left;
    overflow: hidden;
    height: auto;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
    img {
      position: relative;
      display: block;
      min-height: 100%;
      max-width: 100%;
      opacity: 0.8;
    }
    figcaption {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.25em;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &::before,
      &::after {
        pointer-events: none;
      }
       > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
      }
    }
    h3 {
      margin: 0;
      word-spacing: -0.15em;
      font-weight: 800;
      font-family: $subtitulos-font;
    }
  }
}
figure.categorias-efecto {
  background: -webkit-linear-gradient(-45deg,rgba(255,0,34,1)  0%,rgba(0,0,0,1) 100%);
  background: linear-gradient(-45deg, rgba(255,0,34,1) 0%,rgba(0,0,0,1) 100%);
  overflow: hidden;
  border: 1px solid rgba(255,255,255,.5);
  img {
    opacity: .5;
  }
  figcaption::after,
    img {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }
  figcaption::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    content: '';
    opacity: 0;
    -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
    transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  h3 {
    position: relative;
    top: 45%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    color: #fff;
    font-family: $titulo-principal-font;
    font-size: 40px;
  }
  &:hover {
    img {
      opacity: 0.7;
      -webkit-transform: scale3d(1.05,1.05,1);
      transform: scale3d(1.05,1.05,1);
    }
    figcaption::after {
      opacity: 1;
      -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
      transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
    }
    h3 {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      transform: scale3d(1,1,1);
    }
  }
}


/*SECCIÓN EMPRESA*/
.nuestra-empresa {
  padding: 60px 0;
  .texto-empresa {
    p {
      font-family: $parrafo-font;
      font-size: $parrafo;
      line-height: 1.8;
      margin: 0;
      margin: 0 auto;
      color: $color-secundario;
    }
  }
}
.cabecera-empresa{
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3));
}
.video-empresa {
  position: absolute;
  left: 0;
  top: -120px;
  width: 100%;
  height: auto;
  z-index: -10;
  visibility: visible;
}
.titulo-empresa {
  h2{
    color:#fff;
    text-align: center;
    margin-top: 200px;
  }
}

/*CONTACTO*/
.cabecera-contacto {
  position: relative;
  background-repeat: no-repeat;
  max-width:100%;
  box-shadow: 0 3px 5px #666;
  background-image: url('../../images/public/contacto/contacto.jpg');
  background-size: cover;
  background-attachment: fixed;
  margin-bottom: 40px;
  background-position: 100% 100%;
}
.contacto {
  background: linear-gradient(-45deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.9) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(0,0,0,.8), rgba(0,0,0,.9) 100%);
  opacity: 1;
  padding: 90px 0 0 0;
}
.titulo-contacto h2
{
  font-size: 48px;
  text-align: center;
  margin: 0 auto;
  font-family: $titulo-principal-font;
  color:#fff;
}
.contacto-redes {
  background-color: #000;
  padding: 0 0 25px 0;
  .redes-sociales {
    margin:0 auto;
    text-align: center;
    padding-bottom: 20px;
    li{
      margin-top:0;
    }
    .svg-inline--fa {
      font-size: 40px;
    }
  }
}
.texto-contacto {
  p {
    font-size: $parrafo;
    text-align: center;
    padding: 25px 0;
    line-height: 1.4;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: #fff;

    &.oscuro { color: $color-secundario; }
  }
}
.datos-contacto {
  overflow: hidden;
  margin-bottom:20px;
  h4 {
    font-size: $parrafo;
    text-align: center;
    padding: 15px 0;
    line-height: 1.4;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: #fff;
  }
}
.icono-contacto {
  text-align: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  .svg-inline--fa {
    font-size: 25px;
    position: relative;
    top: 12px;
    color:$color-primario;
  }
}

/*FORMULARIO CONTACTO*/
.titulo-contacto h3 {
  text-align: left;
  font-family: $subtitulos-font;
  font-size: $subtitulos;
  color: #dc182d;
  padding: 25px 0 0 0 ;
  margin: 0 auto;
  text-align:center;
  font-weight: 600;
  text-transform: uppercase;
}
.formulario-contacto {
  background: #fff;
  padding:40px 0;
}
.estilo-formulario {
  background-color: #fff;
  .casillero-formulario {
    position: relative;
    margin: 40px 0 10px 0;
    .svg-inline--fa {
      position: absolute;
      top: 10px;
      left: 10px;
      color: $color-primario;
    }
    .form-control {
      background: rgba(255, 255, 255, 0.1);
      border: none;
      border-bottom: 1px solid #545454;
      padding: 10px 10px 10px 35px;
      box-shadow: none;
      color: $color-secundario;
      border-radius:0;
      font-style:italic;
      font-size: $parrafo;
      font-family: $parrafo-font;
      &:focus {
        color: $color-secundario;
        font-style:normal;
        background: rgba(255, 255, 255, 0.3);
      }
      &::-webkit-input-placeholder {
        color: $color-secundario;
      }
      &::-moz-placeholder {
       color: $color-secundario;
      }
      &:-ms-input-placeholder {
        color: $color-secundario;
      }
    }



  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: transparent;
    color: $color-secundario;
    padding: 5px 0 5px 30px;
    option {
      color: #818898;
    }
  }
}
.casillero-formulario.selector-estilo {
    select {
      position: relative;
      z-index: 1;
      background: rgba(255, 255, 255, 0.1);
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 15px;
      right: 10px;
      width: 10px;
      height: 10px;
      border-right: 1px solid #47708c;
      border-bottom: 1px solid #47708c;
      z-index: 0;
      -webkit-transform: translateY(-50%) rotate(45deg);
      -moz-transform: translateY(-50%) rotate(45deg);
      -ms-transform: translateY(-50%) rotate(45deg);
      -o-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
.estilo-formulario.contact-page {
  .casillero-formulario.form-control {
    height: initial;
  }
}
.form-group {
  &:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}

.horario-contactar{
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 00px 10px 10px 35px;
  box-shadow: none;
  color: $color-secundario;
  border-radius: 0;
  font-size: $parrafo;
  font-family: $parrafo-font;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px ;
  line-height: normal;
}
.frm {
  .item {
    .val {
      float: right;
      width: calc(50% - 20px);
      height: 40px;
    }
  }
}
.checkbox {
  .radio_c {
    font-family: $parrafo-font;
    display: inline-block;
    margin-right: $parrafo;
  }
  .svg-inline--fa {
    position: absolute;
    top: 5px;
    left: 0;
    color: $color-primario;
    padding: 0 10px;
  }
  .desc {
    font-family: $parrafo-font;
    margin-right: 15px;
    color: $color-secundario;
  }
}

.boton-enviar {
  text-align: center;
  display: block;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  text-decoration: none;
  margin: 0 auto;
  border-radius: 10px;
  font-family: $parrafo-font;
  font-size: $parrafo;
  background: $color-secundario;
  border: 0;
  font-weight: 600;
  color: #fff;
  padding: 10px 15px 8px 15px;
  vertical-align: middle;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(0,0,0,.2);
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus {
    color: #fff !important;
    text-decoration: none;
  }
  &:hover:before,
  &:focus:before {
    transform: scaleY(1);
  }
  .svg-inline--fa {
    padding-right: 5px;
  }
}

/*Mensaje enviado*/
.mensaje-enviado {
  margin: 150px 0;
  h2 {
    padding-bottom: 35px;
  }
  p {
    font-size: $parrafo;
    text-align: center;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: $color-secundario;
  }
}
.icono-mensaje-enviado {
  margin: 0 auto;
  text-align: center;
  .svg-inline--fa {
    font-size: 70px;
    color: $color-primario;
    padding-bottom: 30px;
  }
}

// FOOTER

footer {
  background-color: #000;
  padding: 40px 0;

  h4 {
    font-size: 16px;
    text-align: left;
    font-family: $parrafo-font;
    color: #fff;
    padding-bottom:3px;
    margin: 0 auto;
    line-height: 1.5;
  }
  p{
    font-size: 13px;
    text-align: left;
    font-family: $parrafo-font;
    color: #fff;
    padding: 0 0 0 25px;
    margin: 0 auto;
    line-height: 1.5;
  }
  .iconos-redes-sociales {
    margin-left: 10px;
  }
  .lista-modelos {
    ul { margin: 0px; padding: 0px; }
    li {
      list-style-type: none;
      border-bottom: 1px dotted $color-primario;
      padding: 3px;
      transition: all 0.35s ease;
      &:hover {
        background-color: rgba(25,25,25,.5);
        transition: all .4s;
      }
      a {
        color: #fff;
        font-size: 13px;
        display: block;
        transition: all 0.35s ease;
        font-family: $parrafo-font;
        padding: 0 0 0 25px;
        &:hover {
          text-decoration: none;
          color: $color-secundario;
          transition: all .4s;
        }
      }
    }
  }
  .fa5-text { padding-left: 10px; }
  .svg-inline--fa { color: $color-primario; }
}
.logo-footer{
  margin: 0 auto;
  text-align: center;
  -webkit-transition: all .9s ease;
  &:hover {
    transform: scale(1.1);
  }
  img{
    height: 40px;
  }
}
.redes-sociales {
  margin: 0 auto;
  li {
    display: inline-block;
    margin: 10px 10px 0 0;
    -webkit-transition: all .9s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  p {
    text-align: center;
  }
  .iconos-redes-sociales .svg-inline--fa {
    color: $color-primario;
    margin-top: 7px;
    margin-left: 9px;
  }
}

@media screen and (max-width: 1200px) {
  .hamburger--collapse-r { display: block; }
  .nav { float: none; margin: 0}
  .navbar { z-index:9999999; padding: 0; border: none; position: fixed; background: rgba(35, 35, 35, 0.95) !important; }
  .navbar-header {width: 100%;}
  ul.menu  { height: 85vh; padding: 0; margin: 0; float: none; overflow: scroll; }
  ul.menu li { float: none; }
  ul.menu li a {float: none; padding: 25px 15px; }
  ul.menu li a:hover {background:rgba(0,0,0,.9)}
  ul.menu > li:hover .sub-menu{ position: relative; background-color:rgba(0,0,0,1);margin: 0 auto;
    width: 100%; }
  ul.menu > li .sub-menu { -webkit-transition:none; margin-top: 0;opacity: 1; position: relative; display: none; }
  ul.menu li.logo-honda a {display: none;}
  .logo-honda {padding-right:0;}
  .scrolled .logo .logo-white { opacity: 1; max-height:40px !important;}
  .sub-menu li a p { border-top:none}
  .logo img { max-height: 50px; margin-left: 15px; }
  .logo .logo-white, .logo .logo-black { max-height: 40px; margin-top: 10px }
  .scrolled .logo .logo-white, .scrolled .logo .logo-black {max-height: 40px; margin-top: 10px}
  .scrolled ul.menu li a { padding: 25px 15px; color: #fff; }
  .navbar-collapse {margin-right: 0 !important; margin-left: 0 !important; border-top:0;background: linear-gradient(-45deg, rgba(255,0,34,.85), #000 100%);  padding: 40px 0;
  background: -webkit-linear-gradient(-45deg, rgba(255,0,34,.85),#000 100%); top:100%; text-align: center; border-top:0; position: absolute;
  left: 0; width: 100%; transition: all .3s; clip-path: circle(0px at top right);}
  .navbar-collapse.collapse { display: none !important;}
  .navbar-collapse.collapse.in { display: block !important; transform: translateX(0%); margin: 0; }
  ul.menu li.logo {display: none;}
  div.topBotomBordersOut a:before { background:transparent;}
  .modelo-auto {padding-top:59px;}
  .cabecera-empresa {padding-top: 59px;}
  .titulo-empresa h2{ margin-top: 150px; }
  .video-empresa {padding-top: 59px; top:0;}
  .video-galeria { max-height:200px; }
}

@media screen and (max-width: 992px) {
  .nav-secundario { padding:0;}
  .pestaña-navegacion { margin-bottom: 25px; }
  .seguridad img { margin-bottom: 25px;}
  .texto-empresa, texto-transmision {margin-top:40px;}
  .caracteristicas-motor { padding: 0 }
  .property_block { padding:90px 0;}
  .titulo-motor h2 {text-align: center; margin:0 auto;}
   .contador-motor {margin-bottom: 50px;}
   .caracteristicas-motor:before {border-left:none;}
   .caracteristicas-motor:after {border-bottom: none;}
   .precios .precio h2 { text-align: center; margin:0 auto;}
  .item.active .item-content { padding: 30px 0; }
  .checkbox .radio_c {   margin-left: 35px; margin-right: 0;  }
  .datos-contacto {margin: 30px 0}
  footer .lista-modelos, footer .redes-sociales {margin: 30px 0;}
  .boton-descargar-archivo {width: 100%}
  .precios .precio h4 { text-align:center; margin:0 auto; padding-bottom:15px;}
  .tabbed-content .item.active .item-content p {text-align:justify;}
  .tabbed-content .item.active .item-content h3 {display:none;}
  .texto-transmision {margin-top:40px !important; }

}

@media all and (min-width: 992px) {
  .tabbed-content .tabs {  display: block; }
  .tabbed-content .tabs li { display: inline-block; }
  .tabbed-content .tabs li a {  display: block; }
  .tabbed-content .item { min-height: 0; }
  .tabbed-content .item::before { display: none;}
  .tabbed-content .tabs { float: left;z-index: 9999; width: 100%; background: rgba(0,0,0,.5);}
  .tabbed-content .tabs li {  display: block; margin: 0;}

}

@media all and (max-width: 800px) {
  .tabs-side .tabs li {  margin-bottom: 2px; }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider .slider-nav-warp { max-width: 690px; }
}

@media only screen and (max-width: 768px) {
  .logo-honda {padding:0 12px; }
  .slider .slider-nav-warp { max-width: 510px; }
  .slider .contenido-slider h2 { font-size: 50px;  }
  .cabecera-empresa { height: 300px; }
  .titulo-empresa h2 {margin-top: 100px;}
  .tabbed-content .item .item-content {-webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);}
}

@media only screen and (min-width: 576px) and (max-width: 766px) {
  .slider .slider-nav-warp { padding: 0 15px; }
}

@media (max-width: 600px) {
  .nav-secundario .menu-secundario > li { margin: 5px; }
  .seleccionar-color li { max-width: 110px;  }
  figure.categorias-efecto h3{font-size:25px;}
  div.topBotomBordersOut a:before {background: transparent;}
  .caracteristicas-motor {padding: 0 }
  .cabecera-empresa { height: 250px; }
  .titulo-empresa h2 {margin-top: 80px; font-size: 35px;}
}


@media only screen and (max-width: 479px) {
  .slider .slider-nav-warp {  max-width: 510px; padding: 0 15px; }
  .slider .contenido-slider h2 { font-size: 35px;}
  .slider .contenido-slider .sboton-slider { margin-bottom: 15px; }
}

.btn-wsp{
  position:fixed;
  width:60px;
  height:60px;
  line-height: 63px;
  bottom:25px;
  right:25px;
  background:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:35px;
  box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  z-index:1000;
  transition: all 300ms ease;
  &:hover{
    background: #fff;
    z-index: 1000;
  }
  &::after {
    content: '';
    width: 55px;
    height: 55px;
    border-radius: 100%;
    border: 3px solid #25d366;
    position: absolute;
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 5s infinite;
  }
  &:hover i::before {
    color: #25d366;
    transform: scale(1.1);
    transition: all .5s ease;
  }
}
