/*SERVICE*/
.service {
  background: linear-gradient(-45deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,.9) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(0,0,0,.8), rgba(0,0,0,.9) 100%);
  opacity: 1;
  padding: 90px 0 0 0;
}
.cabecera-service {
  position: relative;
  background-repeat: no-repeat;
  max-width:100%;
  box-shadow: 0 3px 5px #666;
  background-image: url('../../images/public/service/service-03.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: 100% 20%;
}
.titulo-service h2 {
  font-size: 48px;
  text-align: center;
  margin: 0 auto;
  font-family: $titulo-principal-font;
  color:#fff;
}
.texto-service {
  p {width:60%;}
}
/* Slider */
.seleccionar-auto {
  margin-bottom:40px;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  .slick-track,
  .slick-list{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
      outline: none;
  }
  .dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before,
  &:after {
    display: table;
    content: '';
  }
  &:after {
    clear: both;
  }
}
.slick-initialized {
  .slick-slide {
    display: block;
    margin:0 auto;
  }
}
.slick-loading {
  .slick-slide {
    visibility: hidden;
  }
  .slick-track {
    visibility: hidden;
  }
}
.slick-vertical {
  .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
.slider-for img {
  width:220px;
}
.slider-autos {
  margin:40px 0;
}
.slick-slide {
  margin: 0px 15px;
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  transition: all ease-in-out .3s;
  opacity: .2;
  img {
    display: block;
    margin:0 auto;
  }
  .slick-loading img {
    display: none;
  }
  h4 {
    font-size: 20px;
    text-align: left;
    font-family: "Raleway", sans-serif;
    color: #545454;
    padding: 0;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    text-align:center;
    padding: 5px;
  }
}
.slick-active {
  opacity: .5;
}
.slick-current {
  opacity: 1;
}
.solicitar-turno {
  background-color: #000;
  padding: 25px 0;
  overflow:hidden;
  text-align: center;
  a {
    list-style-type:none;
    text-decoration:none;
  }
}

.boton-turno {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  text-decoration: none;
  margin: 0 auto;
  border-radius: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  background: $color-primario;
  border: 0;
  font-weight: 600;
  letter-spacing:1px;
  color: #fff;
  padding: 10px 15px 8px 15px;
  vertical-align: middle;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(0,0,0,.2);
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus {
    color: #fff !important;
    text-decoration: none;
    border:transparent;
  }
  &:hover:before,
  &:focus:before {
    transform: scaleY(1);
  }
}
.service-seccion {
  padding:60px 0;
}
.service {
  p {
    font-size: $parrafo;
    text-align: center;
    padding: 25px 0 40px 0;
    line-height: 1.8;
    margin: 0 auto;
    font-family: $parrafo-font;
    color: #fff;
  }
}
.service-contenido {
  p {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 1.8;
    margin: 0;
    margin: 0 auto;
    color: #545454;
  }
}
.f1 .steps fieldset {
  text-align: left;
  display:none;

  &:first-child {
    display: block;
  }
}
.f1 .input-error {
  border: 1px solid #f35b3f;
  background-color:#f7f7f7;
}
.f1 h3 {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  color: #dc182d;
  padding: 35px 0 0 0;
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  }

.f1-steps {
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  margin-bottom:55px;
}

.f1-progress {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
}
.f1-progress-line {
  position: absolute;
  top: 0; left: 0;
  height: 1px;
  background: #f35b3f;
}

.formulario-service {
  padding:60px 0 40px 0;
  p{
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    margin: 0 auto;
    padding:15px 0 20px 0;
    font-family: "Raleway", sans-serif;
    color: #888;
  }
}
.registro {
  background: #fff;
  -moz-border-radius: 4px;
  overflow:hidden;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.f1-progress {
  position: absolute;
  top: 24px; left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
}
.f1-progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: $color-primario;
}
.pasos-registro {
  position: relative;
  float: left;
  width: 33%;
  padding: 0 5px;
}
.pasos-registro p {
  color: #ccc;
  text-align:center;
  padding-top: 10px;
}
.pasos-registro.activated p {
  color: $color-primario;
}
.pasos-registro.active p {
  color: $color-primario;
  text-align:center;
  padding-top: 10px;
}
.pasos-registro.activated .iconos-registro {
  background: #fff;
  border: 1px solid $color-primario;
  color: $color-primario;
  line-height: 38px;
}
.pasos-registro.active .iconos-registro {
  width: 48px;
  height: 48px;
  margin-top: 0;
  background:$color-primario;
  font-size: 20px;
  line-height: 48px;
}
.iconos-registro {
  width: 48px;
  height: 48px;
  margin:0 auto;
  background: #ddd;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.iconos-registro .svg-inline--fa {
  text-align: center;
  margin: 0 auto;
  top: 14px;
  position: relative;
  display: block;
}
.solicitud-servicios,
.datos-cliente {
  overflow:hidden;
}
.completar-datos {
  overflow:hidden;
}
.casillero-registro{
  position: relative;
  margin: 25px 0 10px 0;
  .form-control:focus { box-shadow: none; }
  > .svg-inline--fa {
    position: absolute;
    top: 10px;
    left: 10px;
    color: $color-primario;
  }
  .form-control {
    border:none;
    border-bottom: 1px solid #545454;
    padding: 10px 10px 10px 35px;
    box-shadow: none;
    color: $color-secundario;
    border-radius:0;
    font-style:italic;
    font-size: $parrafo;
    font-family: $parrafo-font;
    &:focus {
      color: $color-secundario;
      font-style:normal;
      background: rgba(255, 255, 255, 0.3);
      border-color:$color-primario;
    }
    &::-webkit-input-placeholder {
      color: $color-secundario;
    }
    &::-moz-placeholder {
     color: $color-secundario;
    }
    &:-ms-input-placeholder {
      color: $color-secundario;
    }
  }
}
.gj-datepicker-bootstrap span[role=right-icon].input-group-addon {
  background-color:transparent;
  border-radius:0;
  border-color:transparent;
  color:$color-primario;
  display:flex;
  position:absolute;
  left:0;
  padding:8px 10px;
  z-index:9999;
}
.input-group {
  width:100%;
}
.seleccionar-horario {
  margin:20px 0;
  overflow:hidden;
}
input[type="checkbox"] {
  margin: 3px 0 0;
  line-height: normal;
}
.checkbox {
  text-align:center;
}
.checkbox label {
  background-color:#ddd;
  padding:5px 10px 5px 20px;
}
.checkbox label:focus {
  background-color:red;
}
.checkbox input[type="checkbox"] {
  margin-left: -15px;
}
.texto-confirmacion-service  {
  padding:30px 0;
  overflow:hidden;
  img {
    padding-bottom:20px;
  }
  h4 {
    font-size: 20px;
    color: #545454;
    margin: 0;
    font-family: "Raleway", sans-serif;
    padding-bottom: 15px;
    font-weight: 800;
  }
  .table {
    td {
      text-align: right;
      color: #000;
      font-family:$parrafo-font;
      font-size: 12px;
    }
    td.datos-cargados {
      text-align: left;
      font-family:$parrafo-font;
      font-size: 12px;
      color:#888;
    }
  }
}
.form-group select{
  color: #9a999e;
  font-weight: lighter;
  font-size: 14px;
  width:100%;
  height:34px;
  border:transparent;
  border-bottom:1px solid #545454;
  padding:0px 10px 0px 35px;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  font-style: italic;
  color: #545454;
}
.botones-registro {
  overflow:hidden;
  margin:0 auto;
  text-align:center;
}
.btn-next,
.btn-enviar {
  text-align: center;
  display: inline;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  text-decoration: none;
  margin: 0 auto;
  border-radius: 10px;
  font-family: $parrafo-font;
  font-size: $parrafo;
  background: $color-secundario;
  border: 0;
  font-weight: 600;
  color: #fff;
  padding: 10px 15px 8px 15px;
  vertical-align: middle;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(0,0,0,.2);
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus {
    color: #fff !important;
    text-decoration: none;
    border-radius: 10px;
  }
  &:hover:before,
  &:focus:before {
    transform: scaleY(1);
  }
  .svg-inline--fa {
    padding-right: 5px;
  }
}
.btn-enviar  {
  background-color:$color-primario !important;
}
.btn-prev {
  text-align: center;
  display: inline;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  text-decoration: none;
  margin-right:10px;
  border-radius: 10px;
  font-family: $parrafo-font;
  font-size: $parrafo;
  background: $color-secundario;
  border: 0;
  font-weight: 600;
  color: #fff;
  padding: 10px 15px 8px 15px;
  vertical-align: middle;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(0,0,0,.2);
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:hover,
  &:focus {
    color: #fff !important;
    text-decoration: none;
    border-radius: 10px;
  }
  &:hover:before,
  &:focus:before {
    transform: scaleY(1);
  }
  .svg-inline--fa {
    padding-right: 5px;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #888;
}

#announcement .modal-header { border-bottom: 0px; }
#announcement .modal-body { padding-top: 0px; }
